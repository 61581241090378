<div class="!w-full !bg-slate-800">
  <h1
    mat-dialog-title
    class="!font-thin"
  >
    {{ message }}!
  </h1>
  <div mat-dialog-actions>
    <button
      mat-button
      [mat-dialog-close]="data.name"
      cdkFocusInitial
      class="ml-auto"
      *ngIf="canClose"
    >
      Ok
    </button>
  </div>
</div>
