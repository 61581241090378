import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'codenteam-popup-model',
  templateUrl: './popup-model.component.html',
})
export class PopupModelComponent implements OnInit {
  message;
  canClose?: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupModelComponent>
  ) {
    this.message = data.name;
    this.canClose = data.canClose;

    if (!this.canClose) {
      this.dialogRef.disableClose = true;
    }
  }

  ngOnInit(): void {}
}
